import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Link from '../Link'
import StrapiUrlEncoder from "../../utils/StrapiUrlEncoder"

const AllProductLinks = ({icon, additionalClass, fontWeight}) => {
    return (
        <StaticQuery
            query={graphql`
                query AllProductLinksQuery {
                    allStrapiProducts {
                        nodes {
                            Slug
                            Title
                        }
                    }
                }
            `}
            render={(data) => (
                data.allStrapiProducts.nodes.map((product, i) => (
                    <Link 
                        icon={icon}
                        text={product.Title}
                        url={`/products/${product.Slug}`}
                        additionalClass={additionalClass}
                        fontWeight={fontWeight}
                    />
                ))
            )}
        />
    )
}

export default AllProductLinks