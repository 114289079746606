import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';

const SearchModule = () => {
    const [searchOpen, toggleSearchOpen] = useState(false);
    const [searchQuery, updateSearchQuery] = useState("");

    const updateSearch = (value) => {
        updateSearchQuery(value)
        value.length !== 0 ?
            toggleSearchOpen(true) : toggleSearchOpen(false)
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            navigate(`/search/?query=${searchQuery}`)
        }
    }
    return (
        <div className={`search-module flex ${searchQuery} ${searchOpen ? 'active' : ''} flex-s-between flex-middle`}>
        <input type="text" placeholder="Search" onChange={(e) => updateSearch(e.target.value)} onKeyPress={(e) => handleKeyPress(e)}  />
        <Link to={`/search/?query=${searchQuery}`}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90491 2.28066C8.18464 3.56607 8.18464 5.65014 6.90491 6.93555C5.62517 8.22096 3.55032 8.22096 2.27058 6.93555C0.990851 5.65014 0.990851 3.56607 2.27058 2.28066C3.55032 0.995248 5.62517 0.995248 6.90491 2.28066ZM8.28495 7.3375C9.60792 5.53418 9.45685 2.98198 7.83177 1.34968C6.04014 -0.449894 3.13535 -0.449894 1.34372 1.34968C-0.447907 3.14926 -0.447906 6.06695 1.34372 7.86652C2.98717 9.51727 5.56729 9.65379 7.36567 8.27609L10.8812 11.8072C11.1371 12.0643 11.5521 12.0643 11.808 11.8072C12.064 11.5501 12.064 11.1333 11.808 10.8762L8.28495 7.3375Z" fill="#474848"/>
            </svg>
        </Link>
      </div>
    )
}

export default SearchModule;