import React from "react";
import { Link } from "gatsby";
import Chevron from "../Icons/Chevron";

const LinkTag = ({text, url, icon, additionalClass, fontWeight}) => {
    return (
        <Link to={url} className={additionalClass}>
            <div className="link flex flex-r flex-s-between flex-middle">
                <p className={fontWeight ? fontWeight : 'medium'}>{text}</p>
                {icon === 'chevron' ?
                    <Chevron />
                :null}
            </div>
        </Link>
    )
}

export default LinkTag;